import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Using relative automations and clip launcher`}</h1>
    <p>{`Using relative automations (additive/multiplicative) you can contain any adjustments you make within that clip alone. This will prevent automations from sticking. This is great for very local sound design stuff and ensures your clips will sound the same every time, that is unless you tweak the base parameter values of course.`}</p>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      